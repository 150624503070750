.highlight-container{
  display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background-color: #f8bbd0; */
}

.container{
  /* text-align: justify; */
}

.w-full {
  width: 100%;
}

.row-highlight> .row{
  /* background-color: red; */
  display: flex;
  flex-direction: row;
  width: 100%;
}

.row-item {
  width: 70%;
  height: auto;
  padding: 1.75rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* border-bottom: 5px solid #074b53; */
}

.row-item p{
  min-height: 0;
  font-size: 20px;
  /* font-family: "Noto Serif",serif; */
  font-weight: 500;
  /* font-size: 16px; */
  line-height: 1.4;
  margin-bottom: 30px;
}

.row-item > img {
  display: none;
  /* border: 5px #074b53; */
}

.row-item-media {
  width: 30%;
  height: auto;
  padding: 1rem;
  /* border-radius: 20px; */
  /* border: 5px solid #074b53; */
}
  
.row-item-media img{

  /* width: 80%; */
  border-radius: 50%;
 
}
  @media screen and (max-width: 600px) {
    .row-highlight> .row {
      flex-direction: column;
      margin: 0 !important;
    }

    .row-item {
      width: 100%;
    }

    .row-item > h2 {
      padding-top: 1rem;
    }

    .row-item > img {
      display: block;
    }

    .row-item-media {
      display: none;
    }
  }
  .wpo-about-img-highlight {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 30%;
}