.wpo-world-area,
.wpo-world-area-2 {
    padding-top: 45px;
}

.wpo-world-section-about-us {
    display: block;
    justify-content: space-between;
    align-items: center;
    float: left;
    /* background: url('../../images/logo4.png') no-repeat center center / cover; */
    /* align-items: center; */
    position: relative;
    bottom: 0;
    z-index: 1;
    margin: 0;
/* background-color: red; */
margin-top: 15%;

}



.about-us-background {
    background: rgba(7, 75, 83, 1);
    width: 100%;
    height: 100%;

}




.about-us-background img{
    width: 100%;
    float: left;
    display: block;
    /* height: 650px; */
    position: relative;
    bottom: 0;
}

.wpo-world-section-about-us h2{
    align-items: center;
}

.wpo-world-section-about-us-content {
    /* text-align: center; */
    /* color: #fad2e1; */
    float: left;
    position: relative;
    top: 25%;
    /* height: 100px; */
    
}




.wpo-world-section:before {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(7, 77, 84, 0.851);
    z-index: -1;
}

.wpo-world-section-about-us-content h2 {
    /* max-width: 515px; */
    display: block;
     font-size: 35px;
    font-weight: 500;
    /* line-height: 60px; */
    margin: 0;
    color: #f8bbd0;
    position: relative;
    float: left;

}

.wpo-world-section-about-us-content h2 span{
    /* max-width: 515px; */
    font-size: 40px;
    font-weight: 500;
    color: #f8bbd0;


}

.wpo-world-section-about-us-content p{
    /* max-width: 515px; */
    /* font-size: 40px; */
    /* font-weight: 500; */
    color: #f8bbd0;
    /* line-height: 30px; */
}



.wpo-world-section p {
    max-width: 515px;
    display: block;
    /* font-size: 30px; */
    /* font-weight: 500; */
    /* line-height: 60px; */
    margin: 0;
    color: #f8bbd0;
    position: relative;
    float: left;
}

.wpo-world-section a {
    display: flex;
    align-items: center;
    padding: 15px 20px;
    background: #f8bbd0;
    border-radius: 5px;
    color: #004a52 ;
    font-weight: 600;
}

.wpo-world-section a img {
    margin-right: 10px;
    width: 13px;
    margin-top: -2px;
}

@media(max-width: 1200px) {
    .wpo-world-section a {
        padding: 15px 12px;
    }
}

@media(max-width: 992px) {
    .wpo-world-section h2 {
        max-width: 340px;
        font-size: 34px;
        line-height: 45px;
    }

    .wpo-world-section a {
        padding: 15px 10px;
        font-size: 14px;
    }
    .wpo-world-section {
        flex-wrap: wrap;
    }
    .wpo-world-section h2{
        width: 100%;
        max-width: 100%;
        margin-bottom: 20px;
    }
}

@media(max-width: 450px) {
    .wpo-world-section h2 {
        font-size: 28px;
    }
}


.wpo-world-area-2 .wpo-world-section:before {
    background: rgba(8, 204, 127, 0.5);
}

.wpo-world-area-2 .wpo-world-section a {
    background: #004a52;
}


.wpo-cta-area-2:before {
    background: rgba(8, 204, 127, 0.84);
}

.wpo-cta-text p {
    color: #fff;
}

.wpo-cta-text .theme-btn {
    background: #f8bbd0;
    border: 1px solid #fff;
    color: #333;
}

.wpo-cta-text .theme-btn:hover {
    color: rgb(165, 159, 159);
}

.wpo-cta-text .theme-btn-s2:hover {
    color: #333;
    background: #fff;
}

.wpo-cta-text .theme-btn:hover {
    color: #fff;
    background: transparent;
}

.wpo-world-section a:hover {
    display: flex;
    align-items: center;
    padding: 15px 20px;
    background: #ffffff ;
    border-radius: 5px;
    border: 2px solid white;
    color: #004a52 ;
    font-weight: 600;
}