.volunteer-area {
  padding: 120px 0 45px;
}

.volunter-img {
  padding: 30px;
}

.volunteer-img-wrap {
  box-shadow: 0px 1px 14px 0px rgba(0, 0, 0, 0.15);
}

.volunteer-contact-form form input,
.volunteer-contact-form form select,
.volunteer-contact-form form label,
.volunteer-contact-form form textarea {
  border: 1px solid #d0d4df;
  padding-left: 15px;
  height: 50px;
  width: 100%;

}
.volunteer-contact-form form textarea{
  height: 150px;
}
.volunteer-contact-form form label {
  line-height: 50px;
  font-weight: 400;
  font-size: 14px;
}

.volunteer-contact-form .form-group-in input {
  opacity: 0;
  visibility: hidden;
  position: absolute;
}

.volunteer-contact-form .form-group-in {
  position: relative;
}

.volunteer-contact-form .form-group-in i {
  position: absolute;
  right: 30px;
  top: 17px;
  font-size: 20px;
}

.volunteer-contact-form .form-group {
  margin-bottom: 30px;
}

.volunteer-contact-form {
  max-width: 760px;
  margin: auto;
  padding: 50px 40px;
  background: #fff;
  margin-top: -318px;
  position: relative;
  z-index: 1;
}

.volunteer-contact-form:after {
  position: absolute;
  left: 0px;
  bottom: 0;
  width: 100%;
  height: 100%;
  content: "";
  background: #fff;
  z-index: -1;
}

.volunteer-contact-form:before {
  position: absolute;
  left: -7px;
  bottom: -8px;
  width: 102%;
  height: 47%;
  content: "";
  background: #d9f7eb;
  z-index: -1;
}

.volunteer-contact-form h2 {
  margin-top: 0;
  font-size: 36px;
  text-align: center;
  margin-bottom: 40px;
}

@media(max-width: 992px) {
  .volunteer-contact-form {
    max-width: 500px;
    margin-top: -310px;
}
}

@media(max-width: 767px) {
  .volunteer-contact-form {
      margin-top: 0px;
      padding: 14px 16px;
  }

  .volunteer-contact-form h2 {
      font-size: 23px;
  }

  .volunteer-area {
      padding: 70px 0 0;
  }
  .volunteer-contact-form:before{
    display: none;
  }
}

.volunteer-contact-form .submit-area {
  text-align: center;
}
